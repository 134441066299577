module.exports = {
  // Ecommerce Platform
  platform: 'vtex',

  // Platform specific configs for API
  api: {
    storeId: 'decathlonfstqa',
    workspace: 'master',
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  proxyUrl: {
    store: 'decathlonfstqa.vtexcommercestable',
  },

  // Default channel
  channel: '{"salesChannel":"1"}',
  locale: 'pt-BR',

  // Production URLs
  storeUrl: 'https://faststoreqa.mydkt.com',
  secureSubdomain: 'https://faststoreqa.mydkt.com',
  checkoutUrl: 'https://faststoreqa.mydkt.com/checkout',
  loginUrl: 'https://faststoreqa.mydkt.com/api/io/login',
  signUpUrl: 'https://faststoreqa.mydkt.com/cadastro',
  accountUrl: 'https://faststoreqa.mydkt.com/account',
  apiReviewDecathlon:
    'https://decathlonstore.myvtex.com/api/io/_v/graphql/private/v1',
  faststoreApi: 'https://decathlonstore.vtex.app/api',

  // Search URLs
  decathlonSearch: 'https://decathlonstore.myvtex.com/_v/api/decathlon-search',

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      pdp: '/meia-termica-cano-alto-adulto-de-ski-e-snowboard-638256727/p',
    },
  },

  // E2E CI
  cypress: {
    pages: {
      pages: {
        home: '/',
        pdp: '/meia-termica-cano-alto-adulto-de-ski-e-snowboard-638256727/p',
        collection: '/ciclismo/',
        search: '/pesquisa?q=tenis&sort=score_desc&page=0',
      },
    },
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId: 'GTM-T777CVM',
  },

  token: {
    storeId: 'decathlonstoreapp',
  },

  logRocket: {
    appId: 'izcqja/corebiz-decathlon',
  },

  builder: {
    apiKey: '1a46d27fc8364e2f8b22a0b3b035f833',
  },
}
